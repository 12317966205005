import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<SuccessResponseBody<'/notifications'>['items']>([])
  const lastRequestedAt = ref<SuccessResponseBody<'/notifications'>['requestedAt'] | undefined>(undefined)
  let timeoutId: NodeJS.Timeout | null = null

  async function fetch() {
    const response = await useQuery('/notifications', {
      params: {
        query: { createdAt: lastRequestedAt.value },
      },
    })

    if (response.data) {
      setNotifications([...notifications.value, ...response.data.value.items])
      lastRequestedAt.value = response.data.value.requestedAt
    }

    // Schedule the next request after the current one completes
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(fetch, 60_000)
  }

  function startPolling() {
    fetch()
  }

  function stopPolling() {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  }

  function setNotifications(data: SuccessResponseBody<'/notifications'>['items']) {
    notifications.value = data
  }

  async function markAllAsRead() {
    const { status } = await useQuery('/notifications/mark-as-read', {
      method: 'put',
      params: {
        body: {
          untilDate: lastRequestedAt.value,
        },
      },
      options: { toast: true },
    })

    if (status.value !== 'error') {
      notifications.value.forEach((n) => {
        n.read = true
      })
    }
  }

  async function deleteNotification(id: string) {
    const { status } = await useQuery('/notifications/delete', {
      method: 'delete',
      params: {
        body: {
          id,
        },
      },
      options: { toast: true },
    })

    if (status.value !== 'error') {
      notifications.value = notifications.value.filter(n => n.id !== id)
    }
  }

  async function deleteAllNotifications() {
    const { status } = await useQuery('/notifications/delete-all', {
      method: 'delete',
      params: {
        body: {
          untilDate: lastRequestedAt.value,
        },
      },
      options: {
        toast: true,
      },
    })
    if (status.value !== 'error') {
      notifications.value = []
    }
  }

  return {
    notifications,
    lastRequestedAt,
    deleteAllNotifications,
    deleteNotification,
    startPolling,
    stopPolling,
    setNotifications,
    markAllAsRead,
  }
})
