<script lang="ts" setup>
import type { AuthMe } from '#core/layers/auth/composables/useAuth'
import type { NotificationTypeEnumType } from '@forgd/shared/enums'
import type { ContactTypeEnumType } from '@forgd/supabase'
import { useNotificationsStore } from '#core/services/notifications/state/useNotificationsStore'

const auth = useAuth()
const notificationStore = useNotificationsStore()
const { me } = storeToRefs(auth)
const { notifications } = storeToRefs(notificationStore)
const uiChip = {
  base: 'absolute rounded-full ring-1 ring-white dark:ring-gray-900 flex items-center justify-center text-white dark:text-gray-900 font-normal whitespace-nowrap',
  background: 'bg-background-accent',
  size: {
    '2xl': 'h-[1.125rem] min-w-[1rem] text-xs p-1',
  },
}

function formatMessage(template: string, values: Record<string, any>): string {
  return template.replace(/\[([^\]|]+)(?:\|([^\]]+))?\]/g, (_, key, formatOption) => {
    const value = values[key]

    if (formatOption) {
      return formatValue(value, formatOption)
    }

    if (value === undefined) {
      return `[${key}]` // Keep placeholder if missing
    }
    return value
  })
}

const NotificationTemplatesMap: Record<NotificationTypeEnumType, string> = {
  rfq_created: '🙌 Your RFQ was created successfully.',
  rfq_approved: '📣 There is a new RFQ in Forgd! [projectName] is looking for Market Makers. Send your proposals before [date|date].',
  quote_submitted: '✅ [projectName]`s RFQ has received your submitted quote.',
  quote_canceled: '❌ One of your submitted quotes to [projectName] was cancelled.',
  quote_edited: '✏️ One of your submitted quotes to [projectName]`s RFQ was edited.',
  rfq_competition_update: '[projectName] has received [quotesQty] quotes from Market Makers in the past [hoursLeft] hours, and currently has [loanCallQuotesQty] Loan + Call Option quotes, and [retainerWorkingCapitalQuotesQty] Retainer + Working Capital quotes.',
  rfq_competition_update_1h: '[projectName] has received [quotesQty] quotes from Market Makers in the past [hoursLeft] hour, and currently has [loanCallQuotesQty] Loan + Call Option quotes, and [retainerWorkingCapitalQuotesQty] Retainer + Working Capital quotes.',
  rfq_time_remaining_24h: '⏳ “Accepting Quotes” window for [projectName]’s RFQ-Round [round] will close in 24 hours. You still have time to fine tune your proposals.',
  rfq_time_remaining_1h: '🚨 Last Call. “Accepting Quotes” window for [projectName]’s RFQ-Round [round] will close in 1 hour.',
  rfq_round_1_quotes_accepted_selected_market_maker: '🎉 Congratulations! [projectName] has selected one or more of your Round 1 quotes.',
  rfq_round_1_quotes_accepted_other_market_maker: '❌ [projectName]`s RFQ has not selected your quotes.',
  rfq_round_1_quotes_requoted_selected_market_maker: '🎉 Congratulations! [projectName] has selected one or more of your quotes for a requote. See the RFQ outcome, and prepare your new proposals.',
  rfq_round_1_quotes_requoted_other_market_maker: '👀 [projectName]’s RFQ has moved to the next phase. Unfortunately none of your proposals were selected on Round 1. See the outcome and learn about the selection preferences.',
  rfq_round_2_quotes_accepted_selected_market_maker: '🎉 Congratulations! [projectName] has selected one or more of your Round 2 quotes. See the RFQ outcome, and prepare your new proposals.',
  rfq_round_2_quotes_accepted_other_market_maker: '❌ [projectName]`s RFQ has not selected your quotes.',
  missing_data_drops: `❗️We didn't receive any daily data during the last three days.`,
}

const notificationDataSource = computed(() => {
  return notifications.value.map((n) => {
    return {
      id: n.id,
      createdAt: n.createdAt,
      read: n.read,
      text: formatMessage(NotificationTemplatesMap[n.type as NotificationTypeEnumType], n.data),
    }
  })
})
const showPreferencesModal = ref(false)
const contactAddresses = ref<{ type: ContactTypeEnumType, address: string, enabled: boolean }[]>([])
const unreadQty = computed(() => notificationDataSource.value.filter(n => !n.read).length)
const noData = computed(() => notificationDataSource.value.length === 0)
const chipText = computed(() => {
  if (unreadQty.value === 0) {
    return ''
  }
  return unreadQty.value > 9 ? '9+' : unreadQty.value.toString()
})

function onClose(v: boolean) {
  if (!v) {
    notificationStore.markAllAsRead()
  }
}

async function savePreferences() {
  if (contactAddresses.value.length) {
    await useQuery('/notifications/user/preferences', {
      method: 'post',
      params: {
        body: {
          email: !!contactAddresses.value.find((address: { type: string }) => address.type === 'email')?.enabled,
          telegram: !!contactAddresses.value.find((address: { type: string }) => address.type === 'telegram')?.enabled,
        },
      },
      options: { toast: true },
    })
  }
  showPreferencesModal.value = false
}

function onPreferenceChange(address: string, enabled: boolean) {
  const notification = contactAddresses.value.find((c: { address: string }) => c.address === address)
  if (notification) {
    notification.enabled = enabled
  }
}

watch(me, (val: AuthMe) => {
  contactAddresses.value = []
  if (val.email) {
    contactAddresses.value.push({ type: 'email', address: val.email, enabled: true })
  }
  if ('telegram' in val && val.telegram) {
    contactAddresses.value.push({ type: 'telegram', address: val.telegram, enabled: true })
  }
}, { deep: true, immediate: true })

onMounted(() => {
  notificationStore.startPolling()
})

onUnmounted(() => {
  notificationStore.stopPolling()
})
</script>

<template>
  <div data-el="CoreAppNotifications">
    <UPopover @update:open="onClose">
      <UChip v-if="unreadQty" :text="chipText" size="2xl" position="top-left" :ui="uiChip">
        <UiButton icon="i-heroicons-bell" size="sm" />
      </UChip>
      <UiButton v-else icon="i-heroicons-bell" size="sm" />
      <template #panel>
        <div class="flex flex-col rounded-lg w-[21.875rem] divide-y divide-solid divide-border-default">
          <div class="flex items-center justify-between px-3 py-4">
            <div class="flex items-center gap-2">
              <UiIcon name="i-heroicons-bell" class="w-5 h-5" />
              <span class="text-xs">
                Notifications
              </span>
            </div>
            <span class="text-xs" :class="unreadQty === 0 ? 'text-text-disabled' : 'text-[#3F4D62]'">
              {{ unreadQty }} unread
            </span>
          </div>
          <UiNoData
            v-if="noData"
            icon="i-heroicons-inbox"
            title="You are all caught up!"
            message="Stay tuned for future notifications."
            class="[&>div]:min-h-40"
          />
          <div v-else class="flex flex-col min-h-40 max-h-[25.25rem] pl-5 py-2 gap-2 overflow-y-auto">
            <div
              v-for="notification in notificationDataSource"
              :key="notification.id"
              class="relative flex flex-col gap-1 border-l-4 text-xs pl-2 pr-6"
              :class="notification.read ? 'border-[#8E8E7F]' : 'border-background-accent'"
            >
              <span class="font-semibold">
                {{ notification.text }}
              </span>
              <span class="text-gray-600">
                {{ formatValue(notification.createdAt, 'time:relative,3') }}
              </span>
              <UiButton
                size="xs"
                variant="ghost"
                icon="i-heroicons-x-mark"
                class="absolute right-1"
                @click="notificationStore.deleteNotification(notification.id)"
              />
            </div>
          </div>
          <div class="flex items-center p-2 justify-around">
            <UiButton label="Clear All" icon="i-heroicons-trash" trailing :disabled="noData" size="sm" class="w-[8.75rem]" @click="notificationStore.deleteAllNotifications" />
            <UiButton label="Mark All As Read" icon="i-heroicons-check" trailing :disabled="noData" size="sm" @click="notificationStore.markAllAsRead" />
            <UiButton icon="i-heroicons-cog-6-tooth" size="sm" @click="showPreferencesModal = true" />
          </div>
        </div>
      </template>
    </UPopover>
    <UiModal :is-open="showPreferencesModal" class="bg-white" @close="showPreferencesModal = false">
      <div class="space-y-4 font-display">
        <div class="space-y-2">
          <UIcon
            name="i-heroicons-bell"
            class="w-10 h-10 text-forgd-primary-300"
          />
          <h1 class="text-xl font-semibold text-primary-900">
            Notifications Settings
          </h1>
          <p class="text-forgd-gray-600 text-sm">
            Configure the channels where you prefer to receive notifications from Forgd. All received notifications are relative to RFQs available in the platform, and your participation in them.
          </p>
        </div>
        <div v-for="contact in contactAddresses" :key="contact.address" class="p-2 flex items-center justify-between rounded-lg bg-neutral-600/30">
          <div class="flex flex-col">
            <span class="text-sm font-semibold">
              Receive {{ contact.type }} notifications
            </span>
            <span class="text-xs text-gray-600">
              {{ contact.address }}
            </span>
          </div>
          <UiToggle :model-value="contact.enabled" @change="onPreferenceChange(contact.address, $event)" />
        </div>
        <UiButton class="w-full" @click="savePreferences">
          Save Preferences
        </UiButton>
      </div>
    </UiModal>
  </div>
</template>
